import http from '~/utils/http'

export class RankListApi {
  // @POST('/jav/ranking/query')
  // Future<RankVideoPageResponse> query(@Body() QueryRankVideoParam param);
  static query(params: QueryRankVideoParam, opt?: HttpOptions) {
    return http.post<RankVideoPageResponse>({
      ...opt,
      url: '/jav/ranking/query',
      body: params
    })
  }
}
